<template>
    <div class="d-flex justify-content-center align-items-center vh-100">
        <div class="col-lg-5 mx-auto">
            <div class="card border-0">
                <div class="card-body p-0 auth-header-box">
                    <div class="text-center p-3">
                        <h4 class="mt-3 mb-1 font-weight-semibold font-18">Oops! Sorry page does not found</h4>
                    </div>
                </div>
                <div class="card-body">
                    <div class="ex-page-content text-center">
                        <h1 class="mt-5 mb-4">404!</h1>
                        <h5 class="font-16 text-muted mb-5">Somthing went wrong</h5>
                    </div>
                    <router-link to="/" class="btn btn-primary btn-block waves-effect waves-light">Back to Home <i class="fas fa-redo ml-1"></i></router-link>
                </div>
            </div><!--end card-->
        </div>
    </div>
</template>

<script>
export default {
    name: "404"
}
</script>

<style scoped>

</style>